// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  surcussale: getIcon('ic_surcussale'),
  analytics: getIcon('ic_analytics'),
  borlette: getIcon('ic_borlette'),
  limite: getIcon('ic_limite'),
  tirage: getIcon('ic_tirage'),
  rapport: getIcon('ic_rapport'),
  supervisor: getIcon('ic_supervisor'),
  agent: getIcon('ic_agent')
};

export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      // MANAGEMENT : BOLETTE
      {
        title: 'tableau de bord',
        path: PATH_DASHBOARD.general?.app,
        icon: ICONS.limite
      },
      // MANAGEMENT : TIRAGE
      {
        title: 'configuration',
        path: PATH_DASHBOARD.tirage?.configuration,
        icon: ICONS.tirage,
        children: [
          { title: 'Tirages', path: PATH_DASHBOARD.tirage?.info_tirage },
          {
            title: 'mariage gratuit Surcussale',
            path: PATH_DASHBOARD.tirage?.intervalMaSur,
            perm: 'view_supervisor'
          },
          { title: 'primes', path: PATH_DASHBOARD.tirage?.configuration },
          { title: 'prime par Agent', path: PATH_DASHBOARD.tirage?.configurationAgent},
          {
            title: 'superviseur',
            path: PATH_DASHBOARD.supervisor?.root,
            icon: ICONS.supervisor,
            children: [
              { title: 'Ajouter', path: PATH_DASHBOARD.supervisor?.newSupervisor },
              { title: 'listes', path: PATH_DASHBOARD.supervisor?.list }
            ]
          }
        ]
      },
      // MANAGEMENT : RAPPORT
      {
        title: 'vendeur',
        path: PATH_DASHBOARD.agent?.root,
        icon: ICONS.agent,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.agent?.newAgent },
          { title: 'listes', path: PATH_DASHBOARD.agent?.list }
        ]
      },
      {
        title: 'surveillance',
        path: PATH_DASHBOARD.borlette?.statistique,
        icon: ICONS.borlette,
        children: [

          { title: 'Lots gagnant', path: PATH_DASHBOARD.tirage?.list },
          // { title: 'fiches vendu', path: PATH_DASHBOARD.borlette?.list },
          { title: 'blocage boule', path: PATH_DASHBOARD.borlette?.blocage_boule },
          { title: 'statistiques', path: PATH_DASHBOARD.borlette?.statistique },
          { title: 'listes options', path: PATH_DASHBOARD.borlette?.lotto },
          { title: 'Limite Jeu', path: PATH_DASHBOARD.configuration_borlette?.type_game },
          { title: 'Limite Jeu Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_game_agent },
          { title: 'Limite Boule', path: PATH_DASHBOARD.configuration_borlette?.type_boule },
          { title: 'Limite Boule Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_boule_agent },
          { title: 'Tracabilites Agents', path: PATH_DASHBOARD.user?.tracabilite_agent },
          { title: 'Tracabilites Superviseurs', path: PATH_DASHBOARD.user?.tracabilite_supervisor }
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      },

      // MANAGEMENT : SUPERVISOR

      // MANAGEMENT : SUCCURSALE
      {
        title: 'succursale',
        path: PATH_DASHBOARD.surcussale?.root,
        icon: ICONS.surcussale,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.surcussale?.newSurcussale },
          { title: 'listes', path: PATH_DASHBOARD.surcussale?.list }
        ]
      },

      // MANAGEMENT : AGENT
      {
        title: 'rapports',
        path: PATH_DASHBOARD.rapport?.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Ventes', path: PATH_DASHBOARD.rapport?.root },
          { title: 'Control Agent', path: PATH_DASHBOARD.rapport?.agent },
          { title: 'fiches vendu', path: PATH_DASHBOARD.borlette?.list },
          { title: 'fiches gagnants', path: PATH_DASHBOARD.borlette?.list_fgagnant },
          { title: 'fiches eliminés', path: PATH_DASHBOARD.borlette?.list_fsupp }
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      },

      // MANAGEMENT : COMPANY
      {
        title: 'mon compte',
        icon: ICONS.user,
        path: PATH_DASHBOARD.user?.root,
        children: [
          { title: 'Editer Profile', path: PATH_DASHBOARD.user?.account },
          { title: 'Mes tracabilites', path: PATH_DASHBOARD.user?.tracabilite_me }
        ]
      }
    ]
  }
];

export const sidebarConfigSupervisor = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      // MANAGEMENT : BOLETTE
      {
        title: 'tableau de bord',
        path: PATH_DASHBOARD.general?.app,
        icon: ICONS.limite
      },
      // MANAGEMENT : TIRAGE
      {
        title: 'configuration',
        path: PATH_DASHBOARD.tirage?.configuration,
        icon: ICONS.tirage,
        children: [
          { title: 'Tirages', path: PATH_DASHBOARD.tirage?.info_tirage }
          // { title: 'primes', path: PATH_DASHBOARD.tirage?.configuration },
          // {
          //   title: 'superviseur',
          //   path: PATH_DASHBOARD.supervisor?.root,
          //   icon: ICONS.supervisor,
          //   children: [
          //     { title: 'Ajouter', path: PATH_DASHBOARD.supervisor?.newSupervisor },
          //     { title: 'listes', path: PATH_DASHBOARD.supervisor?.list }
          //   ]
          // }
        ]
      },
      // MANAGEMENT : RAPPORT
      {
        title: 'vendeur',
        path: PATH_DASHBOARD.agent?.root,
        icon: ICONS.agent,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.agent?.newAgent },
          { title: 'listes', path: PATH_DASHBOARD.agent?.list }
        ]
      },
      {
        title: 'surveillance',
        path: PATH_DASHBOARD.borlette?.statistique,
        icon: ICONS.borlette,
        children: [
          { title: 'Lots gagnant', path: PATH_DASHBOARD.tirage?.list },
          { title: 'fiches vendu', path: PATH_DASHBOARD.borlette?.list },
          { title: 'blocage boule', path: PATH_DASHBOARD.borlette?.blocage_boule },
          { title: 'statistiques', path: PATH_DASHBOARD.borlette?.statistique },
          { title: 'listes options', path: PATH_DASHBOARD.borlette?.lotto },
          { title: 'Limite Jeu', path: PATH_DASHBOARD.configuration_borlette?.type_game },
          { title: 'Limite Jeu Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_game_agent },
          { title: 'Limite Boule', path: PATH_DASHBOARD.configuration_borlette?.type_boule },
          { title: 'Limite Boule Par Agent', path: PATH_DASHBOARD.configuration_borlette?.type_boule_agent },
          { title: 'Tracabilites Agents', path: PATH_DASHBOARD.user?.tracabilite_agent }
          // { title: 'Tracabilites Superviseurs', path: PATH_DASHBOARD.user?.tracabilite_supervisor }
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      },

      // MANAGEMENT : SUPERVISOR

      // MANAGEMENT : SUCCURSALE
      // {
      //   title: 'succursale',
      //   path: PATH_DASHBOARD.surcussale?.root,
      //   icon: ICONS.surcussale,
      //   children: [
      //     { title: 'Ajouter', path: PATH_DASHBOARD.surcussale?.newSurcussale },
      //     { title: 'listes', path: PATH_DASHBOARD.surcussale?.list }
      //   ]
      // },

      // MANAGEMENT : AGENT
      {
        title: 'rapports',
        path: PATH_DASHBOARD.rapport?.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Ventes', path: PATH_DASHBOARD.rapport?.root },
          { title: 'Control Agent', path: PATH_DASHBOARD.rapport?.agent },
          { title: 'fiches vendu', path: PATH_DASHBOARD.borlette?.list },
          { title: 'fiches gagnants', path: PATH_DASHBOARD.borlette?.list_fgagnant },
          { title: 'fiches eliminés', path: PATH_DASHBOARD.borlette?.list_fsupp }
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      }

      // MANAGEMENT : COMPANY
      // {
      //   title: 'mon compte',
      //   icon: ICONS.user,
      //   path: PATH_DASHBOARD.user?.root,
      //   children: [
      //     { title: 'Editer Profile', path: PATH_DASHBOARD.user?.account },
      //     { title: 'Mes tracabilites', path: PATH_DASHBOARD.user?.tracabilite_me }
      //   ]
      // }
    ]
  }
];

export default { sidebarConfig, sidebarConfigSupervisor };
